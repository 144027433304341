import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Text, Heading } from "rebass/styled-components";
import Container from "../../components/UI/Container";
import { LangContext } from "../../components/Layout/langContext";
import ImgMarkupTwoCol from "../../components/Blocks/ImgMarkupTwoCol";
import Products from "../../components/Blocks/Products";
import Banner from "../../components/Blocks/Variants/BannerFull";
import Parallax from "../../components/Blocks/Parallax/Parallax";

const ProductTemplate = ({ data }) => {
  const { currentLanguage } = useContext(LangContext);
  const dataFrontmatter = data.markdownRemark.frontmatter;
  const dataImgMarkupTwoCol = {};
  dataImgMarkupTwoCol["rows"] = dataFrontmatter.rows;
  const dataProducts = {};
  dataProducts["imageBanner"] = dataFrontmatter.imageBanner;
  dataProducts["comingSoon"] = dataFrontmatter.comingSoon ? true : false;
  const dataBanner = {};
  dataBanner["blockSettings"] = [];
  dataBanner["blockSettings"]["align"] = "center";
  dataBanner["blockSettings"]["bgImg"] = "/img/coco-tree.png";
  dataBanner["title"] = currentLanguage == "fr" ? "Contactez-nous" : "Contact us";
  dataBanner["supTitle"] = currentLanguage == "fr" ? "Une question ?" : "Get in touch";
  dataBanner["content"] =
    currentLanguage == "fr"
      ? "A modifier directement dans Content de ProductTemplate"
      : "Thank you for your interest. Our lovely sales team will be in touch with you shortly";
  dataBanner["cta"] = [];
  dataBanner["cta"]["href"] = "/contact";
  dataBanner["cta"]["label"] = currentLanguage == "fr" ? "Contactez-nous" : "Contact us";

  return (
    <Box sx={{}}>
      <Container>
        {dataFrontmatter.rows && dataFrontmatter.rows.length > 0 && (
          <ImgMarkupTwoCol data={dataImgMarkupTwoCol} />
        )}

        {dataFrontmatter.Parallax && (
          <>
            <Text mt={[4, 4, 6]} variant="supTitle" textAlign="center">
              {currentLanguage == "en" ? "We produce" : "Nous produisons"}
            </Text>
            <Heading as="h2" variant="variants.blockTitleCenter" mb={8}>
              {currentLanguage == "en" ? "Beverages for all" : "Des boissons pour tous"}
            </Heading>
            <Parallax parallax={dataFrontmatter.Parallax} />
          </>
        )}
        {data.markdownRemark.html && (
          <Box
            pt={[2, 4, 6]}
            sx={{
              textAlign: "center",
              hr: {
                width: "60%",
                margin: "20px auto",
                border: 0,
                borderTop: "4px solid #db3f3b"
              }
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
          </Box>
        )}
        <Products data={dataProducts} uuid={dataFrontmatter.uuid} />
      </Container>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden"
        }}
      >
        <Banner data={dataBanner} />
      </Box>
    </Box>
  );
};

ProductTemplate.propTypes = {
  data: PropTypes.object
};

export default ProductTemplate;
