import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";
import { withTheme } from "styled-components";
import Parallax from "parallax-js";
import { Box } from "rebass/styled-components";

const ParallaxComp = ({ parallax }) => {
  const scene = useRef(null);

  useEffect(() => {
    new Parallax(scene.current, {
      // relativeInput: true
    });
  }, []);
  return (
    <Box
      sx={{
        marginBottom: "-6px",
        ul: { listStyle: "none", margin: 0, padding: 0 },
        img: { width: "100%" }
      }}
    >
      <ul id="scene" ref={scene}>
        <li className="layer" data-depth="0">
          <img src={parallax.background?.publicURL} />
        </li>
        <li className="layer" data-depth="-0.5">
          <img
            style={{ filter: "blur(3px)", animation: "wave 7.77778s 0.1s infinite linear" }}
            src={parallax.beforebackground?.publicURL}
          />
        </li>
        <li className="layer" data-depth="0.1">
          <img src={parallax.subject?.publicURL} />
        </li>
        <li className="layer" data-depth="1.8">
          <img
            src={parallax.front?.publicURL}
            style={{ animation: "wave2 4s 0.1s infinite linear" }}
          />
        </li>
      </ul>
    </Box>
  );
};

ParallaxComp.propTypes = {
  parallax: PropTypes.any,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary: PropTypes.any
    })
  })
};

export default withTheme(ParallaxComp);
