import PropTypes from "prop-types";
import React from "react";
import { Flex, Box, Heading, Button, Text } from "rebass/styled-components";
import { withTheme } from "styled-components";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import Link from "../../UI/Link";
import Icon from "../../UI/Icon";
import BGbanner from "../../../theme/img/brush-banner.svg";
import BGbannerGray from "../../../theme/img/brush-banner-gray.svg";

const Banner = ({ data, theme, slug }) => {
  const bannerIMG = slug && slug.includes("index") ? BGbannerGray : BGbanner;
  return (
    <Wrapper
      data={data}
      py={[0, 0, 0]}
      bg="primary"
      sx={{
        position: "relative",
        overflow: "hidden"
      }}
    >
      <Flex
        py={[9, 10, 11]}
        justifyContent="center"
        sx={{
          "&:before, &:after": {
            content: "''",
            position: "absolute",
            top: 0
          },
          "&:before": {
            width: "100%",
            height: "100%",
            backgroundColor: theme.colors.primary,
            opacity: ".75",
            zIndex: 1,
            left: 0
          },
          "&:after": {
            width: "110vw",
            height: "100%",
            transform: "translate(-50%, -2px)",
            backgroundImage: "url(" + bannerIMG + ")",
            backgroundSize: "contain",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "top center",
            zIndex: 2,
            left: "50%"
          },
          "& > *": {
            position: "relative",
            zIndex: 3,
            color: "white"
          }
        }}
      >
        <Box>
          {data.supTitle && (
            <Text variant="supTitle" color="white">
              {data.supTitle}
            </Text>
          )}
          {data.title && (
            <Heading as="h2" variant="variants.blockTitleWhiteCenter">
              {data.title}
            </Heading>
          )}
          {data.content && (
            <Box mt={4} maxWidth="586px" mx="auto">
              <Markdown>{data.content}</Markdown>
            </Box>
          )}
          {data.cta.href && (
            <Box
              mt={6}
              sx={{
                a: {
                  textDecoration: "none"
                }
              }}
            >
              <Link href={data.cta.href}>
                <Button variant="invert">
                  {data.cta.label}
                  <Icon icon="arrow-next" />
                </Button>
              </Link>
            </Box>
          )}
        </Box>
      </Flex>
    </Wrapper>
  );
};

Banner.propTypes = {
  slug: PropTypes.string,
  data: PropTypes.shape({
    supTitle: PropTypes.any,
    title: PropTypes.any,
    content: PropTypes.any,
    cta: PropTypes.any
  }),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary: PropTypes.any
    })
  })
};

export default withTheme(Banner);
