import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import CustomBreadcrumb from "../../components/UI/CustomBreadcrumb";
import Content from "./Content";
import PropTypes from "prop-types";

const ProductTemplate = ({ data, path, pageContext }) => {
  const dataFrontmatter = data.markdownRemark.frontmatter;
  const imageBanner = dataFrontmatter.imageBanner || null;
  return (
    <Layout
      title={dataFrontmatter.title}
      path={path}
      headerImage={imageBanner}
      supTitle={dataFrontmatter.supTitle}
      {...pageContext}
    >
      {pageContext.slug && !pageContext.slug.includes("index") && (
        <CustomBreadcrumb crumbLabel={dataFrontmatter.title} {...pageContext} />
      )}
      <Content data={data} />
    </Layout>
  );
};
ProductTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.shape({
    breadcrumb: PropTypes.any,
    i18nPaths: PropTypes.any,
    lang: PropTypes.any,
    slug: PropTypes.any
  }),
  path: PropTypes.any
};
export default ProductTemplate;

export const pageQuery = graphql`
  query ProductByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        uuid
        weight
        comingSoon
        title
        supTitle
        imageCover {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 350) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageBanner {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 350) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        Parallax {
          background {
            publicURL
          }
          subject {
            publicURL
          }
          front {
            publicURL
          }
          beforebackground {
            publicURL
          }
        }
        rows {
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          contentTitle
          content
          textPosition
          cta {
            label
            href
          }
        }
      }
    }
  }
`;
